
import { Component, Vue } from "vue-property-decorator"
import UiMenu from "@/app/shared/components/ui-menu.vue"

@Component({
  components: { UiMenu }
})

export default class UiList extends Vue {
  isCreateModalVisible = false
  isEditModalVisible = false
  isDeleteModalVisible = false
  newCategoryName = ''
  editCategoryName = ''
  categoryToEditId: number | null = null
  selectedCategoryId: number | null = null
  categoryToDeleteId: number | null = null
  
  get headings() {
    return this.$store.getters['articleStore/headings']
  }

  mounted() {
    this.$store.dispatch('articleStore/fetchHeadings')
  }

  openCreateModal(): void {
    this.newCategoryName = ''
    this.isCreateModalVisible = true
  }

  async confirmCreate(): Promise<void> {
    if (this.newCategoryName) {
      try {
        await this.$store.dispatch('articleStore/createHeading', this.newCategoryName)
        this.closeCreateModal()
      } catch (error) {
        this.$eventBus.$emit('notify', {
          type: 'error',
          message: 'Не удалось создать категорию'
        })
      }
    }
  }

  async confirmEdit(): Promise<void> {
    if (this.categoryToEditId !== null && this.editCategoryName) {
      try {
        await this.$store.dispatch('articleStore/updateHeading', {
          id: this.categoryToEditId,
          name: this.editCategoryName
        })
        this.closeEditModal()
      } catch (error) {
        this.$eventBus.$emit('notify', {
          type: 'error',
          message: 'Не удалось изменить категорию'
        })
      }
    }
  }

  async confirmDelete(): Promise<void> {
    if (this.categoryToDeleteId !== null && this.selectedCategoryId !== null) {
      try {
        await this.$store.dispatch('articleStore/deleteHeading', {
          id: this.categoryToDeleteId,
          changeHeadingId: this.selectedCategoryId
        })
        this.closeDeleteModal()
      } catch (error) {
        this.$eventBus.$emit('notify', {
          type: 'error',
          message: 'Не удалось удалить категорию'
        })
      }
    }
  }

  closeCreateModal(): void {
    this.isCreateModalVisible = false
  }

  openEditModal(category: { id: number; name: string }): void {
    this.editCategoryName = category.name
    this.categoryToEditId = category.id
    this.isEditModalVisible = true
  }

  closeEditModal(): void {
    this.isEditModalVisible = false
  }

  openDeleteModal(id: number): void {
    if (this.headings.length === 1) {
      this.$eventBus.$emit('notify', {
        type: 'error',
        message: 'Невозможно убрать последнюю рубрику'
      })
      
      return
    }
    this.categoryToDeleteId = id
    this.isDeleteModalVisible = true
  }

  closeDeleteModal(): void {
    this.isDeleteModalVisible = false
    this.selectedCategoryId = null
  }
}

