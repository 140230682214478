import { MutationTree } from 'vuex'

import { TArticleState } from '@/app/article/shared/types'
import Article from '@/app/article/shared/models/Article'
import initialState from './state'
import { TPagination } from "@/app/article/shared/models/Publication"

export default <MutationTree<TArticleState>>{
  update (state: TArticleState, article: Article): void {
    state.entity = article
  },
  
  updateField (state: TArticleState, { field, value }: { field: keyof Article, value: never }) {
    state.entity[field] = value
  },
  updateList (state: TArticleState, list: Article[]): void {
    state.list = list
  },
  deleteField (state: TArticleState, id: number): void {
    state.list = state.list.filter((fieldItem) => fieldItem.id !== id)
  },
  updatePagination(state: TArticleState, pagination: TPagination): void {
    state.pagination = pagination
  },
  updatePage (state: TArticleState, page: number): void {
    state.pagination.page = page
  },
  clear (state: TArticleState): void {
    state.entity = initialState().entity
  }
}
