import { render, staticRenderFns } from "./smart-section-drawer.vue?vue&type=template&id=53834f1e&scoped=true"
import script from "./smart-section-drawer.vue?vue&type=script&lang=ts"
export * from "./smart-section-drawer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53834f1e",
  null
  
)

export default component.exports