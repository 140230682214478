import { Heading, Tag, TagGroup } from '@/app/article/shared/types'
import axios, { AxiosResponse } from 'axios'

class ArticleService {
  static async fetchHeadings(): Promise<AxiosResponse<Heading[], any>>{
    return axios.get('/heading')
  }

  static async fetchSections(): Promise<AxiosResponse<TagGroup[], any>> {
    return axios.get('/tag/1/type')
  }

  static async createHeading(name: string): Promise<AxiosResponse<Heading, any>> {
    return axios.post('/heading', { name })
  }

  static async updateHeading(id: number, name: string): Promise<void> {
    return axios.put(`/heading/${id}`, { name })
  }

  static async deleteHeading(id: number, changeHeadingId: number): Promise<void> {
    return axios.delete(`/heading/${id}`, { data: { changeHeadingId } })
  }

  static async fetchCategories(tags: Tag[]): Promise<AxiosResponse<Tag[], any>> {
    const params = tags
      .map((el, index) => `parent[${index}]=${el.id}`)
      .join('&')
    const url = `/tag/2/type?${params}`

    return axios.get(url)
  }
}

export default ArticleService
