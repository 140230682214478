// lib
import axios, { AxiosResponse } from 'axios'

// app
import Response from '@/app/shared/models/response'
import Article, { IArticle } from '@/app/article/shared/models/Article'
import Publication, { TPagination } from '@/app/article/shared/models/Publication'
import { TArticleList } from "@/app/article/shared/types"

export default class ArticleService {
  static async fetch (id: number): Promise<Article> {
    const response: AxiosResponse<Response<IArticle>> = await axios.get(`/articles/${id}`)
    const fetchedJSON = response.data.data

    return new Article(fetchedJSON)
  }

  static async fetchTable (query: string): Promise<TArticleList> {
    const response: AxiosResponse<Response<{ pages: Publication[] }, TPagination>> = await axios.get(`/pages/table${query}`)

    const fetchedArticles: Publication[] = response.data.data.pages

    return {
      list: fetchedArticles && fetchedArticles.map(v => new Publication(v)),
      pagination: response.data.pagination
    }
  }

  static create (data: Article): Promise<AxiosResponse<Response<{ id: number, createdAt: string, updatedAt: string }>>> {
    return axios.post('/articles/create', { ...data, id: undefined })
  }

  static update (data: IArticle): Promise<AxiosResponse<Response<{ id: number, createdAt: string, updatedAt: string }>>> {
    return axios.post('/articles/update', { ...data })
  }

  static delete(id: number): Promise<AxiosResponse<Response<{ id: number }>>> {
    return axios.delete<Response<{ id: number }>>(`/articles/${id}`)
  }
}
