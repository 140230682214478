// lib
import { ActionTree } from 'vuex'

// app
import { TArticleList, TArticleState } from '@/app/article/shared/types'
import { TRootState } from '@/app/shared/types'
import ArticleService from '@/app/article/shared/services/ArticleService'
import Article from '@/app/article/shared/models/Article'
import AbstractConstructor from '@/app/constructor/shared/models'

export default <ActionTree<TArticleState, TRootState>>{
  async fetch ({ commit }, id: number) {
    const article = await ArticleService.fetch(id)

    commit('update', article as Article)
    commit('editor/update', (article?.content || []) as AbstractConstructor[])

    return article
  },

  async fetchTable ({ commit }, query: string) {
    const articleList: TArticleList = await ArticleService.fetchTable(query)

    if (articleList.list) {
      commit('updateList', articleList.list)
    }

    if (articleList.pagination) {
      commit('updatePagination', articleList.pagination)
    }

    return articleList.list
  },

  async deleteArticle ({ commit }, id: number) {
    await ArticleService.delete(id)

    commit('deleteField', id)
  },

  async create ({ commit }, article: Article) {
    const response = await ArticleService.create(article)

    return response.data.data
  },

  async update ({ commit, state, getters }, article: Article) {
    const editor = state?.editor?.entity

    const response = await ArticleService.update(article.toJsonWith({
      content: editor,
      link: getters.link
    }))

    return response.data.data
  }
}
