import { Module } from 'vuex'
import { state } from './state'
import { mutations } from './mutations'
import { actions } from './actions'
import { getters } from './getters'
import { HeadingState } from './types'

const articleStore: Module<HeadingState, any> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default articleStore
