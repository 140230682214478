import { MutationTree } from 'vuex'
import { TagGroup, Tag, Heading } from '../../types'
import { HeadingState } from './types'

export const mutations: MutationTree<HeadingState> = {
  setHeadings(state, headings: Heading[]): void {
    state.headings = headings
  },
  addHeading(state, heading: Heading): void {
    state.headings.push(heading)
  },
  updateHeading(state, updatedHeading: Heading): void {
    const index = state.headings.findIndex((h) => h.id === updatedHeading.id)
    if (index !== -1) {
      state.headings[index] = updatedHeading
    }
  },
  removeHeading(state, id: number): void {
    state.headings = state.headings.filter((h) => h.id !== id)
  },
  setSections(state, sections: TagGroup[]): void {
    state.sections = sections
  },
  setCategories(state, categories: Tag[]): void {
    state.categories = categories
  }
}
