
import { Vue, Component } from 'vue-property-decorator'

import { TPageOptions, TView, THeader } from '@/app/article/shared/types'
import Publication from '@/app/article/shared/models/Publication'
import Article from '@/app/article/shared/models/Article'
import UiMenu from '@/app/shared/components/ui-menu.vue'
import AppArticleListViewFilters from "@/app/article/list/app-article-list-view-filters.vue"
import SmartSectionDrawer from '@/app/article/shared/components/smart-section-drawer.vue'
import { debounce } from '@/app/shared/utils/debounce'
import { DEBOUNCE_PENDING, HEADERS, SORT_DIRECTIONS } from './app-article-list-view.constants'
import { toQueryStringWithPrefix } from "@/app/shared/utils/toQueryStringWithPrefix"

@Component({
  components: {
    AppArticleListViewFilters,
    SmartSectionDrawer,
    UiMenu
  }
})
export default class AppArticleListView extends Vue {
  tree = []

  items = [
    {
      name: 'Инструменты',
      status: 'green',
      children: [
        {
          name: 'Виброплиты',
          isArticle: true,
          status: 'green'
        },
        {
          name: 'Выбор инструмента',
          isArticle: true,
          status: 'red'
        }
      ]
    }
  ]

  headers: THeader[] = HEADERS
  view: TView = 'table'
  sectionDrawer = false

  debouncedUpdateFilters = debounce(this.updateFilters, DEBOUNCE_PENDING)

  sort: Record<string, string> = {}

  filters: Record<string, string> = {
    title: '',
    section: '',
    updated: ''
  }

  onInputFilter(filters: Record<string, string>): void {
    this.filters = filters

    this.debouncedUpdateFilters(filters)
  }

  get table(): Article[] {
    return this.$store.state.article.list
  }

  get page(): number {
    return this.$store.state.article.pagination.page
  }

  set page(page: number) {
    this.$store.commit('article/updatePage', page)
  }

  get totalPages(): number {
    const totalProducts: number = this.$store.state.article.pagination.totalProducts
    const itemsPerPage: number = this.$store.state.article.pagination.itemsPerPage

    if ((totalProducts <= 0 || itemsPerPage <= 0) && itemsPerPage > totalProducts) {
      return 1
    }

    return Math.ceil(this.$store.state.article.pagination.totalProducts / this.$store.state.article.pagination.itemsPerPage)
  }

  onClickCreate(): void {
    this.$router.push({ name: 'ArticleEditor', params: { id: 'new' } })
  }

  deleteArticle(id: number) {
    this.$store.dispatch('article/deleteArticle', id)

    if (this.table.length === 0) {
      this.fetchListTable()
    }
  }

  fetchListTable(): void {
    const filters: Record<string, string> = {}

    for (const filtersKey in this.filters) {
      if (this.filters[filtersKey]) {
        filters[filtersKey] = this.filters[filtersKey]
      }
    }

    const strSort = toQueryStringWithPrefix(this.sort, 'sort')
    const strFilters = toQueryStringWithPrefix(filters, 'filter')

    let resultStr = `?page=${this.page}`

    if (strSort) {
      resultStr += `&${strSort}`
    }

    if (strFilters) {
      resultStr += `&${strFilters}`
    }

    if (resultStr !== window.location.search) {
      this.$router.push(this.$route.path + resultStr)
    }

    this.$store.dispatch('article/fetchTable', resultStr)
  }

  handleToggleView(): void {
    if (this.view === 'table') {
      this.view = 'tree'
    } else {
      this.view = 'table'
    }
  }

  handleOpenPublication(item: Publication): void {
    if (item.typeId === 2) {
      this.$router.push({ name: 'ArticleEditor', params: { id: String(item.id) } })

      return
    }

    this.$store.dispatch('article/section/fetch', item.id)

    this.sectionDrawer = true
  }

  onUpdatePagination(page: number): void {
    if (page === Number(this.$route.query?.page)) {
      return
    }

    this.page = page

    this.fetchListTable()
  }

  onUpdateOptions(options: TPageOptions): void {
    const page: number = this.$store.state.article.pagination.page

    const sortName: string = options.sortBy[0]
    const sortDirection: boolean = options.sortDesc[0]

    this.page = page

    if (sortName) {
      this.sort = {
        [sortName]: sortDirection
          ? SORT_DIRECTIONS.DESC
          : SORT_DIRECTIONS.ASK
      }
    } else {
      this.sort = {}
    }

    this.fetchListTable()
  }

  updateFilters(): void {
    this.page = 1

    this.fetchListTable()
  }
}
