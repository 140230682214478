// app
import IMetaPage from '@/app/shared/models/IMetaPage'
import Model from '@/app/shared/models/Model'
import { Tag, TagGroup } from '../types'

export interface IPublicationParent {
  id: number | null
  title?: string
}

export interface IPublication extends IMetaPage {
  id?: number | null
  title: string
  link: string
  typeId?: number
  parent: IPublicationParent
  isActive: boolean
  isSitemap?: boolean
  datePublished?: string
  dateModified?: string
  dateCreated?: string
}

export class PublicationParent implements IPublicationParent {
  id!: number | null
  title?: string

  constructor (props: IPublicationParent) {

    Object.assign(this, props)
  }
}

export default class Publication extends Model<IPublication> implements IPublication {
  id?: number | null
  title!: string
  typeId?: number
  isActive!: boolean
  isSitemap?: boolean
  link!: string
  parent!: PublicationParent
  dateModified?: string
  dateCreated?: string
  datePublished?: string
  metaDescription?: string
  metaTitle?: string
  microdata?: string

  constructor (props: IPublication) {
    super(props)

    Object.assign(this, props)

    this.parent = new PublicationParent(props.parent)
  }
}

export type TPagination = {
  itemsPerPage: number,
  page: number,
  totalProducts: number
}
