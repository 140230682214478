import { ActionTree } from 'vuex'
import ArticleService from '@/app/shared/services/ArticleService'
import { Heading, Tag, TagGroup } from '../../types'
import { HeadingState } from './types'
import { AxiosResponse } from 'axios'

export const actions: ActionTree<HeadingState, any> = {
  async fetchHeadings({ commit }): Promise<void> {
    try {
      const response: AxiosResponse<Heading[]> = await ArticleService.fetchHeadings()
      commit('setHeadings', response.data)
    } catch (error) {
      console.error('Failed to fetch headings:', error)
      throw error
    }
  },

  async createHeading({ commit }, name: string): Promise<void> {
    try {
      const response: AxiosResponse<Heading> = await ArticleService.createHeading(name)
      commit('addHeading', response.data)
    } catch (error) {
      console.error('Failed to create heading:', error)
      throw error
    }
  },

  async updateHeading({ commit }, { id, name }: { id: number; name: string }): Promise<void> {
    try {
      await ArticleService.updateHeading(id, name)
      commit('updateHeading', { id, name })
    } catch (error) {
      console.error('Failed to update heading:', error)
      throw error
    }
  },

  async deleteHeading({ commit }, { id, changeHeadingId }: { id: number; changeHeadingId: number }): Promise<void> {
    try {
      await ArticleService.deleteHeading(id, changeHeadingId)
      commit('removeHeading', id)
    } catch (error) {
      console.error('Failed to delete heading:', error)
      throw error
    }
  },

  async fetchSections({ commit }): Promise<void> {
    try {
      const response: AxiosResponse<TagGroup[]> = await ArticleService.fetchSections()
      commit('setSections', response.data)
    } catch (error) {
      console.error('Failed to fetch sections:', error)
      throw error
    }
  },

  async fetchCategories({ commit }, tags: Tag[]): Promise<void> {
    try {
      const response: AxiosResponse<Tag[]> = await ArticleService.fetchCategories(tags)
      commit('setCategories', response.data)
    } catch (error) {
      console.error('Failed to fetch categories:', error)
      throw error
    }
  }
}
