// app
import Publication, { IPublication, PublicationParent } from './Publication'
import ArticleAuthor, { IArticleAuthor } from './ArticleAuthor'
import ConstructorItem, { IConstructorItem } from '@/app/constructor/shared/models/ConstructorItem'
import AbstractConstructor from '@/app/constructor/shared/models'
import ConstructorBlock from '@/app/constructor/shared/models/ConstructorBlock'
import ConstructorComponent from '@/app/constructor/shared/models/ConstructorComponent'
import { Heading, Tag, TagGroup } from '../types'

export interface IArticle extends IPublication {
  typeId?: 2
  author: IArticleAuthor
  subtitle?: string
  coverImage?: string
  content?: IConstructorItem[]
  heading: Heading | null
  tags: Tag[]
  tagGroups: TagGroup[]
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
interface IArticleOverriden extends IArticle {
  content?: IConstructorItem
}

export default class Article extends Publication implements IArticleOverriden {
  typeId?: 2 = 2
  subtitle?: string
  coverImage?: string
  author!: ArticleAuthor
  content?: ConstructorItem
  heading!: Heading | null
  tagGroups!: TagGroup[]
  tags: Tag[] = this.finalTags
  
  private get initialTags(): Tag[] {
    if (this.tagGroups.length === 2) {
      return [
        ...this.tagGroups[0].tags.map(category => ({ ...category, type: 1 })),
        ...this.tagGroups[1].tags.map(section => ({ ...section, type: 2 }))
      ]
    }

    if (this.tagGroups.length === 1 && this.tagGroups[0].name === 'Рубрика') {
      return this.tagGroups[0].tags.map(category => ({ ...category, type: 1 }))
    }

    return []
  }

  private get finalTags(): Tag[] {
    return this.tags?.length ? this.tags : this.initialTags
  }

  constructor (props: IArticle & { content?: IConstructorItem[] }) {
    super(props)

    Object.assign(this, props)

    this.author = new ArticleAuthor(props.author)
    this.parent = new PublicationParent(props.parent)

    if (props.content) {
      const constructorApp = new ConstructorBlock({
        type: 'main',
        attrs: {}
      })
      constructorApp.content = this._makeConstructorContent(props.content)
      this.content = constructorApp
    }
  }

  private _makeConstructorContent (content: IConstructorItem[]): AbstractConstructor[] {
    return content.map(v => {
      if (['article-block-simple'].includes(v.type)) {
        const block = new ConstructorBlock(v)
        block.content = this._makeConstructorContent(v.content || [])

        return block
      }

      return new ConstructorComponent(v)
    })
  }

  toJsonWith (data : {
    content: AbstractConstructor | AbstractConstructor[],
    link: string
  }): any {
    let contentResult = data.content

    if (data.content instanceof ConstructorBlock) {
      contentResult = data.content.content
    }

    const result: any = {
      ...this,
      parent: {
        id: this.parent.id,
      },
      author: this.author.id,
      typeId: undefined,
      dateCreated: undefined,
      dateModified: undefined,
      dateModify: undefined,
      datePublished: undefined,
      content: JSON.stringify(contentResult),
      heading: this.heading,
      microdata: JSON.stringify(this.microdata),
      tagGroups: this.tagGroups,
      tags: this.finalTags
    }

    if (data.link) {
      result.link = data.link
    }

    return result
  }
}
