// lib
import { RouteConfig } from "vue-router"

export default <RouteConfig[]>[
  {
    path: "/article/editor/:id",
    name: "ArticleEditor",
    component: () => import("../editor/app-article-editor.vue"),
  },
  {
    path: "/article/list",
    alias: "/article",
    name: "ArticleList",
    component: () => import("../list/app-article-list-view.vue"),
  },
  {
    path: "/article/rubrikslist",
    alias: "/article",
    name: "RubriksList",
    component: () => import("../rubriks/app-rubrik-list-view.vue"),
  }
]
