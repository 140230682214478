import Article from '@/app/article/shared/models/Article'
import Section from '@/app/article/shared/models/Section'
import Publication, { TPagination } from "@/app/article/shared/models/Publication"

type TArticleState = {
  entity: Article
  list: Article[]
  editor?: {
    entity?: any
  },
  pagination: TPagination
}

type TSectionState = {
  entity: Section,
  list: Section[]
}

enum PageMode {
  Create,
  Update
}

type Tag = {
  id: number,
  type?: number,
  name: string
}

type TagGroup = {
  id: number,
  name: string,
  tags: Tag[]
}

type Heading = {
  id: number,
  name?: string
}

type TPageOptions = {
  page: number,
  itemsPerPage: number,
  sortBy: string[],
  sortDesc: boolean[]
}

type TArticleList = {
  list: Publication[],
  pagination?: TPagination
}

type TView = 'table' | 'tree'

type THeader = {
  value: string,
  text: string,
  sortable?: boolean
}

export type {
  Tag,
  TagGroup,
  Heading,
  TArticleState,
  TSectionState,
  TPageOptions,
  TArticleList,
  TView,
  THeader
}

export {
  PageMode
}